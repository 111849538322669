// Body
// Text på mörkt
$sand-stone: #ede2d6;

// Text på body
// Brödtext
// I logotyp
$copper-stone: #a06c48;

// Text på Pine National
// Text i knappar
$verdigris-bright: #93b4a9;

// Knappar
$pine-national: #43564f;

// Sekundär brödtext
$plain-graphite: #3f3f3f;
