@import "assets/styles/colors.scss";

/* Bootstrap overrides */
$theme-colors: (
  "primary": $pine-national,
);

@import "~bootstrap/scss/bootstrap.scss";

/* Fonts */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Nevada Serial";
  src: local("Nevada Serial"),
    url("assets/fonts/NevadaSerialLight.otf") format("opentype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nevada Serial", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3 {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
  color: $copper-stone;
}

/* MAIN CSS */

$body-max-width: 960px;

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;

  background-color: $sand-stone !important;
}

.app-container {
  position: relative;
  min-height: 100%;

  background-color: $sand-stone;
}

@media only screen and (min-width: $body-max-width) {
  .my-content {
    width: $body-max-width;
  }
  .header {
    height: 150px;
  }

  .header-image {
    border-radius: 20px;
  }
}

@media only screen and (max-width: $body-max-width) {
  .header {
    height: 100px;
  }

  .my-content {
    width: 100%;
  }
}

.header {
  width: 100%;
  text-align: center;

  background-color: $sand-stone;
}

.logo {
  margin: auto;
  height: 130%;
  transform: translateY(-10%);

  color: $copper-stone;
}

a {
  text-decoration: none;
  color: $pine-national;
}

a:hover {
  text-decoration: none;
  color: $pine-national;
}

.my-content {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;

  background-color: $sand-stone;
}

.home-content {
  padding: 0 30px;

  color: $copper-stone;
}

.reorder-1 {
  order: 1;
}

.reorder-2 {
  order: 2;
}

@media only screen and (min-width: $body-max-width) {
  .horizontal-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
  }

  .m-lr-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .m-auto {
    margin: auto;
  }

  .horizontal-child-img {
    text-align: center;

    // padding-left: 27px;
    // padding-right: 27px;

    flex-grow: 1;
  }

  .horizontal-child {
    text-align: center;

    padding-left: 27px;
    padding-right: 27px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    p.grow {
      flex-grow: 1;
    }
  }

  .mb-lg {
    // TODO: Remove this if spacing between containers is enough
    $m-lg: 0px;
    margin-top: $m-lg;
    margin-bottom: $m-lg;
  }
}

@media only screen and (max-width: $body-max-width) {
  .horizontal-child {
    margin-bottom: 40px;
  }

  .map-image {
    margin: 40px 0px;
  }
}

.horizontal-child {
  flex-basis: 33%;
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin-bottom: 10px;
    font-size: 18pt;
  }
}

.horizontal-child-img {
  flex-basis: 33%;
  text-align: center;
  max-width: 500px;
  min-width: 0;
  margin-left: auto;
  margin-right: auto;
}

.welcome {
  text-align: center;
}

.welcome h2 {
  margin: 20px 0 10px 0;
  text-align: center;
  font-size: 25pt;
}

.welcome * {
  text-align: center;
}

.welcome p {
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;

  padding: 10px;
  color: $sand-stone;
  font-size: 10px;
  text-align: center;
}

.footer p {
  // color: #bbb5ae;
  color: $sand-stone;
}

.header-image {
  width: 100%;

  @media only screen and (min-width: $body-max-width) {
    margin-top: 20px;
    margin-bottom: 50px;
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
  }
}

.my-button {
  background-color: $pine-national !important;
  color: $verdigris-bright !important;
  border-radius: 30px !important;
  padding-right: 15px !important;

  -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.2);
}

.icon {
  margin-right: 10px;
}

.instagram-icon {
  font-size: 40px;
  color: $pine-national;

  &:hover {
    cursor: pointer;
  }
}

.hashtag-container {
  margin-top: 90px;
  margin-bottom: 50px;

  text-align: center;

  p {
    margin: 0;
  }
}

.hashtag {
  color: $copper-stone;
  font-family: "Nevada Serial", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.navbar-dark > a.navbar-brand {
  color: $verdigris-bright;
}
